.intro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  background-color: #449af8;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      > p {
        font-family: "Mulish";
        color: #0d082c;
        width: fit-content;
        padding: 12px;
        background-color: white;
        box-shadow: 1 grey;
        border-top-left-radius: 3px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      > p:nth-child(3) {
        min-width: 200px;
        max-width: 230px;
      }

      .header-logo {
        > img {
          width: 220px;
          height: 65px;
          margin-top: 35px;

          &.avatar {
            width: 65px;
            height: 65px;
            padding-bottom: 5px;
            margin-right: 10px;
          }
        }
      }
    }

    .card {
      background-color: white;
      margin-top: 70px;
      max-width: 300px;
      max-height: 450px;
      padding: 30px 30px 30px 30px;
      border: 1px solid lightgray;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      > h1 {
        font-family: "Poppins";
        font-weight: 800;
        font-size: 28px;
        color: #0d082c;
      }

      > p {
        font-family: "Mulish";
        font-size: 16px;
        color: #0d082c;
      }

      > button {
        width: 100%;
        height: 50px;
        color: white;
        margin-top: 30px;
        background-color: #449af8;
        opacity: 0.9;
        border-radius: 5px;
        border-style: hidden;
        transition: opacity 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  > img {
    width: 185px;
    height: 50px;
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .melonloop-ad {
    position: absolute;
    bottom: 5px;
    right: 12px;
    color: white;
    font-size: 10px;
    font-family: "Mulish", sans-serif;
  }
}
