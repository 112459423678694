.App {
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-header {
  width: 100vw;
  height: 100px;
  background-color: #449af8;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  > img {
    position: absolute;
    right: 25px;
    top: 15px;
    width: 250px;
    height: 70px;

    &.logo {
      position: absolute;
      z-index: 0;
      left: 25px;
      width: 220px;
      height: 65px;
    }
  }

  @media screen and (max-width: 600px) {
    > img {
      margin-top: 15px;
      width: 150px;
      padding-top: 5px;
      height: 40px;

      &.logo {
        width: 170px;
        height: 45px;
        padding-top: unset;
      }
    }
  }

  > p {
    position: absolute;
    left: 25px;
    font-size: 32px;
    color: white;
    font-weight: 400;
    font-family: "Mulish";
  }
}

.hidden {
  display: none;
}
