.error-message {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: darkred;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
