.bot {
  min-height: calc(100vh - 100px);

  .webchat__bubble__content {
    border-radius: 10px !important;
    border-style: hidden !important;
    background-color: #f1f7ff !important;
  }

  .ac-pushButton {
    border-radius: 10px;
    border-style: hidden !important;
  }

  .webchat__microphone-button__icon {
    fill: #0063b1;
  }

  .webchat__send-box__main {
    position: fixed;
    bottom: 0;
    width: 100vw;
  }

  .webchat__basic-transcript__transcript {
    margin-bottom: 50px;
  }
}
